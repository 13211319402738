import { useMoralis, useERC20Balances } from "react-moralis";
import { Skeleton, Table } from "antd";
import { getEllipsisTxt } from "../helpers/formatters";
import { useERC20Transfers } from "../hooks/useERC20Transfers";
import { getExplorer } from "../helpers/networks";
import "antd/dist/antd.css";
import abi from "./abi_usdc.js";
import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const styles = {
  title: {
    fontSize: "30px",
    fontWeight: "700",
  },
};
function ERC20(props) {
  const { data: assets } = useERC20Balances(props);
  const { ERC20Transfers, chainId } = useERC20Transfers();
  const [avaxAmt, setAvaxAmt] = React.useState(5);
  const [convR, setConvR] = React.useState(67);
  const { Moralis, user, isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } = useMoralis();

  const getCR = async () => {
    const options = {
      contractAddress: "0xcfEaBe9C6d0DAAc5A2214Ba942afB8C8dABF277F",
      functionName: "getConversionRate",
      abi,
      params:{avaxAmount: String(1)},
    };
    
    const transaction = await Moralis.executeFunction(options); // see https://docs.moralis.io/moralis-server/web3/web3
    setConvR(parseInt(transaction));
  }

  useEffect(() => {
    if(isWeb3Enabled){
      getCR();
    }
  },[isWeb3EnableLoading,isWeb3Enabled])

  const handleChange = (event, newValue) => {
    setAvaxAmt(event.target.value);
  };
 
  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      render: (logo) => (
        <img
          src={logo || "https://etherscan.io/images/main/empty-token.png"}
          alt="nologo"
          width="28px"
          height="28px"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name,
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (symbol) => symbol,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (value, item) => {
        console.log(value);
        console.log(item.decimals);
        const units = Moralis.Units.FromWei(value, 18);
        console.log(units);
        return parseFloat(units).toFixed(2);
      },
    },
    {
      title: "Address",
      dataIndex: "token_address",
      key: "token_address",
      render: (address) => getEllipsisTxt(address, 5),
    },
  ];

  const columns2 = [
    {
      title: "Token",
      dataIndex: "address",
      key: "address",
      render: (token) => getEllipsisTxt(token, 8),
    },
    {
      title: "From",
      dataIndex: "from_address",
      key: "from_address",
      render: (from) => getEllipsisTxt(from, 8),
    },
    {
      title: "To",
      dataIndex: "to_address",
      key: "to_address",
      render: (to) => getEllipsisTxt(to, 8),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value, item) => {
        const units = Moralis.Units.FromWei(value, 18); //item.decimals has a problem
        return parseFloat(units).toFixed(2);
      },
    },
    {
      title: "Hash",
      dataIndex: "transaction_hash",
      key: "transaction_hash",
      render: (hash) => (
        <a href={`${getExplorer(chainId)}tx/${hash}`} target="_blank" rel="noreferrer">
          View Transaction
        </a>
      ),
    },
  ];

  let key = 0;
  //console.log(avaxAmt)
  const converted = !isNaN(avaxAmt) ? parseFloat(avaxAmt)*convR : 1*convR;
  return (
    <div style={{ width: "65vw", padding: "15px" }}>
      <h1>Get USDC</h1>
      <p>USDC is used on NFCop to pay license fees.<br/>
         We use <a href="https://chain.link/" target="_blank">Chainlink</a> to determine the exchange rate.</p>
      <div style={{width: "500px", padding: "20px"}}>
        <div>
          <TextField onChange={handleChange} id="avaxAmount" label="Amount in AVAX" variant="outlined" value={avaxAmt} />
        </div>
        <div style={{marginTop: "10px"}}>
          <TextField onChange={handleChange} id="usdcAmount" label="Amount in USDC" variant="outlined" disabled value={`~${converted}`} />
        </div>
        <br/>
        <div><Button variant="contained" onClick={async ()=>{
          const msgValue = parseFloat(avaxAmt)*(10**18);
          console.log(msgValue);
          const options = {
            contractAddress: "0xcfEaBe9C6d0DAAc5A2214Ba942afB8C8dABF277F",
            functionName: "SwapAVAX",
            abi,
            params:{_address: user.attributes.ethAddress},
            msgValue
            }
          const transaction = await Moralis.executeFunction(options); // see https://docs.moralis.io/moralis-server/web3/web3
          console.log(transaction);
        }}>Change AVAX to USDC</Button></div>
        <br/>
        <p>Avalanche supports USDC natively but we cannot get it on the Fuji Testnet. On the Mainnet we recommend <a target="_blank" href="https://traderjoexyz.com/#/trade">Trader Joe</a> or <a target="_blank" href="https://app.pangolin.exchange/#/swap">Pangolin</a>.</p>
      </div>
      <h1>Token Balances</h1>
      <Skeleton loading={!assets}>
        <Table
          dataSource={assets}
          columns={columns}
          rowKey={(record) => {
            return record.token_address;
          }}
        />
      </Skeleton>
      <h1>Transactions</h1>
      <Skeleton loading={!ERC20Transfers}>
        <Table
          dataSource={ERC20Transfers}
          columns={columns2}
          rowKey={(record) => {
            key++;
            return `${record.transaction_hash}-${key}`;
          }}
        />
      </Skeleton>
    </div>
  );
}
export default ERC20;
