import { Card, Timeline, Typography } from "antd";
import React, { useMemo, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import Gallery from "react-photo-gallery";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import TabPanel from "./TabPanel.jsx";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { dnsEncode, namehash } from "@ethersproject/hash"

import abi from "./abi_whitelist.js";
import abi_erc721 from "./abi_erc721.js";
import abi_erc1155 from "./abi_erc1155.js";
import abi_usdc from "./abi_usdc.js";
import abi_usdc_erc20 from "./abi_usdc_erc20.js";
import abi_listing from "./abi_listing.js";
import abi_minting from "./abi_minting.js";
//var InfiniteScroll = require('react-infinite-scroll-component');

const erc721_contract = "0xd2f3605513871b08a176c7972cb226098673b0aa"; //"0x73374074a68f14082b02f07cf5977d1d881863cc";
const erc1155_contract = "0xa4208958be17f42d5153dabefb1bb36ad4a406dd";
const usdc_contract = "";
const usdc_erc20_contract = "0x12fBD7f44A515Ab64BFa64a1F6C285F3c793faf8";
const listing_contract = "0xc9Cf9eF9E42054264749f55c1FDc3F14e3c6b6Cf";
const mint_contract = "0x04e460D57E9Ea17299E4513FD7Cdb4D416e62181" // this is the current one Jan30
const whitelist_contract = "0x62CbE5ef67b5C07F478b6BE9aCC23Dda2D944B7c";

const photos = [
  {
    src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
    width: 1,
    height: 1
  },
  {
    src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
    width: 3,
    height: 4
  }
];

const owner_dict = {
  "0xe7E3E925E5dcFeaF5C5CEBfbc6EfD4B404B0e607": "Jonathan Lehner",
  "0xC117E7247be4830D169da13427311F59BD25d669": "Aurelien Pelissier",
  "0x0254207ECB2B26e63e3648cD2e490112b8dF3EC9": "Chang Li",
  "0x27e5F4501A2a2F3a749860eB970aa57D63d44391": "Andreas Müller"
}

const { Text } = Typography;

const styles = {
  Market: {
    display: "flex",
    flexWrap: "wrap",
    WebkitBoxPack: "start",
    justifyContent: "flex-start",
    margin: "0 auto",
    maxWidth: "1000px",
    width: "100%",
    gap: "10px",
    flexDirection: "column"
  },
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
    padding: "5px",
  },

  timeline: {
    marginBottom: "-45px",
  },
};


export default function Market({ isServerInfo }) {
  const { Moralis, user, isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } = useMoralis();
  const address = user && user.attributes.ethAddress;
  const [value, setValue] = useState(0);
  const [hasWeb3, setHasWeb3] = useState(false);
  const [search, setSearch] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [copyrights, setCopyrights] = useState(true);
  const [licenses, setLicenses] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [listings, setListings] = useState();  
  const get_listings = async () => {
    const options7 = {
      contractAddress: listing_contract,
      functionName: "get_all_copyright_prices",
      abi: abi_listing,
      params: {},
    }
    //console.log(options7)
    const transaction7 = await Moralis.executeFunction(options7);
    //console.log(transaction7)
    setListings(transaction7);
  }

  useEffect(() => {
    Moralis.enableWeb3().then(()=>setHasWeb3(true));
  },[])

  useEffect(() => {
    if(hasWeb3){
      get_listings();
    }
  },[hasWeb3])

  const [token_id_ipfs, setTokenIdIpfs] = useState({});  
  const token_id_ipfs_dict = {};
  const get_CR_token = async (token_id, price) => {
    //console.log(token_id)
    const options = {
      contractAddress: erc721_contract,
      functionName: "tokenURI",
      abi: abi_erc721,
      params: {tokenId: token_id},
    }
    const transaction = await Moralis.executeFunction(options);
    fetch(transaction)
      .then(res => res.json())
      .then(async metadata => {
        metadata["price"] = price;
        const options = {
          contractAddress: listing_contract,
          functionName: "Get_owner_of_copyright",
          abi: abi_listing,
          params: {Copyright_tokenID: token_id},
        }
        //Moralis.enableWeb3();
        const ownerAddress = await Moralis.executeFunction(options);
        metadata["owner"] = ownerAddress;
        metadata["type"] = "Copyright";
        token_id_ipfs_dict[token_id] = metadata;
        setTokenIdIpfs(token_id_ipfs_dict);
        get_photos(token_id_ipfs_dict);
        });
  }
  //console.log(token_id_ipfs);

  useEffect(async () => {
    //console.log(listings)
    hasWeb3 && listings &&
    listings.map((price, token_id) => {
      if(price != 0){
        get_CR_token(token_id, price);
      }
    });
  }, [listings,isWeb3Enabled]);
  
  const [photos2, setPhotos2] = useState([]);
  const get_photos = (token_id_ipfs_dict) => {
    const photos = Object.entries(token_id_ipfs_dict).map((tuple) => {
      //console.log(tuple);
      const token_id = tuple[0];
      const metadata = tuple[1];
      const photo = {}
      photo["token_id"] = token_id;
      photo["name"] = metadata.name;
      photo["type"] = metadata.type;
      photo["price"] = metadata.price;
      photo["owner"] = metadata.owner;
      //photo["metadata"] = metadata;
      photo["src"] = metadata.image;
      photo["width"] = metadata.width>1000? metadata.width/10 : metadata.width<200? metadata.width*10 : metadata.width;
      photo["height"] = metadata.width>1000? metadata.height/10 : metadata.width<200? metadata.height*10 :metadata.height;
      photo["onClick"] = () => {};
      //console.log(metadata)
      return photo;
    }) || [];
    //console.log(photos);  
    setPhotos2(photos);
  }

  const withdraw = async (token_id) => {
    const options2 = {
      contractAddress: listing_contract,
      functionName: "withdraw_Copyright",
      abi: abi_listing,
      params: {Copyright_tokenID: token_id},
    }
    //console.log(options2)
    const transaction2 = await Moralis.executeFunction(options2);
    //console.log(transaction2); 
  }

  const buy = async (token_id, price) => {
    const options5 = {
      contractAddress: usdc_erc20_contract,
      functionName: "approve",
      abi: abi_usdc_erc20,
      // this only works as string because big number error
      params: {spender: listing_contract, amount: (price*10**18).toLocaleString('fullwide', {useGrouping:false})},
    }
    console.log(options5)
    const transaction5 = await Moralis.executeFunction(options5);
    console.log(transaction5)

    const options2 = {
      contractAddress: listing_contract,
      functionName: "buy_Copyright_with_USDC",
      abi: abi_listing,
      params: {Copyright_tokenID: token_id},
    }
    console.log(options2)
    const transaction2 = await Moralis.executeFunction(options2);
    console.log(transaction2); 
  }

  const render_image = props => {
    //console.log(props)
    return (
      <div key={props.photo.token_id}
        style={{
          height: props.nolimit ? "auto" : props.photo.height,
          width: props.nolimit ? "100%" : props.photo.width,
          position: "relative",
          margin: "10px",
          maxWidth: props.nolimit ? "1000px" : "350px"
        }}
      >
        <img
          style={{maxWidth: props.nolimit ? "1000px" : "350px", objectFit: "cover"}}
          {...props.photo}
          width={props.nolimit ? "100%" : props.photo.width}
          height={props.nolimit ? "auto" : props.photo.height}
        />
        <div style={{background: "lightgray", padding: "5px", position: "absolute", top: "0px"}}>
          <a style={{color: "black", fontSize: "15px", textDecoration: "none"}} onClick={() => {}}>{props.photo.name} ({props.photo.type})</a><br/> 
          <a style={{color: "black", fontSize: "12px", textDecoration: "none"}} onClick={() => {}}>Seller: {owner_dict[props.photo.owner]}</a>
        </div>
        <div style={{background: "lightgray", padding: "5px", position: "absolute", bottom: "0px"}}>
          {props.photo && address && address.toLowerCase() == props.photo.owner.toLowerCase() ? <a onClick={() => withdraw(props.photo.token_id)}>Withdraw</a>
           :
          <a style={{color: "black", fontSize: "12px", textDecoration: "underline"}} onClick={() => buy(props.photo.token_id, props.photo.price)}>Buy for {(props.photo.price*(10**-18)).toFixed(2)} USDC</a>
          }
        </div>
      </div>
    );
  };
  let filteredPhotos = photos2.filter((photo)=>photo.name.toLowerCase().includes(search.toLowerCase()) && (photo.type == "Copyright" ? copyrights : licenses));
  if(maxPrice != ""){
    filteredPhotos = filteredPhotos.filter((photo) => {
      console.log(photo.price*(10**-18))
      console.log(maxPrice)
      return photo.price*(10**-18) <= maxPrice;
    });
  }
  
  return (
    <div style={styles.Market}>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value={0} label="Photos" />
          <Tab disabled value={1} label="Graphics" />
          <Tab disabled value={2} label="3D models" />
        </Tabs>
      </div>
      <div style={{marginBottom: "500px"}}>
        <div style={{position: isMobile ? "fixed" : "relative"}}>
          <Card
            style={styles.card}
            title={""}
          >
            <div style={{display: "flex", flexWrap: "wrap"}}>
              <TextField style={{minWidth: "150px"}} onChange={(e)=>setSearch(e.target.value)} value={search} id="outlined-basic" label="Search" variant="outlined" />
              <TextField style={{minWidth: "150px"}} onChange={(e)=>setMaxPrice(e.target.value)} value={maxPrice} id="outlined-basic" label="Max. price" variant="outlined" />
              <div style={{padding: "10px"}}> 
                <FormGroup>
                  <div style={{display: "flex", flexDirection: "row"}}>
                    <FormControlLabel control={<Checkbox onChange={(e)=>setCopyrights(e.target.checked)} checked={copyrights} />} label="Copyrights" />
                    <FormControlLabel control={<Checkbox onChange={(e)=>setLicenses(e.target.checked)} checked={licenses} />} label="Licenses" />
                  </div>
                </FormGroup>
              </div>
            </div>
          </Card>
          <TabPanel value={value} index={0}>
            <BrowserView>
              <Gallery photos={filteredPhotos} renderImage={render_image}/>
            </BrowserView>
            <MobileView>
              <div style={{overflowY: "scroll", overflowX: "hidden", height: "60vh"}}>
                {filteredPhotos.map((photo)=>render_image({photo, nolimit: true}))}
              </div>
              {/*<InfiniteScroll
                dataLength={filteredPhotos.length} //This is important field to render the next data
                next={()=>{}}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                // below props only if you need pull down functionality
                refreshFunction={()=>{}}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                }
              >
                {JSON.stringify(filteredPhotos)}
              </InfiniteScroll>*/}
            </MobileView>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Gallery photos={photos} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Gallery photos={photos} />
        </TabPanel>
        </div>
      </div>
    </div>
  );
}
