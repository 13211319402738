import { useEffect } from "react";
import { useMoralis } from "react-moralis";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Account from "components/Account";
import Chains from "components/Chains";
import ERC20 from "components/ERC20";
import NFTs from "components/NFTs";
import About from "components/About";
import { Layout } from "antd";
import "antd/dist/antd.css";
import "./style.css";
import Market from "components/Market";
import Text from "antd/lib/typography/Text";
import MenuItems from "./components/MenuItems";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const { Header, Footer } = Layout;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "70px",
    padding: "10px",
  },
  header: {
    position: "fixed",
    zIndex: 99999,
    width: "100%",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
  },
};
const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } = useMoralis();

  // https://github.com/facebook/create-react-app/issues/11771#issuecomment-1017125835
  useEffect(() => { window.process = { ...window.process, }; }, []);

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  return (
    <Layout style={{ height: "100vh", overflow: "auto" }}>
      <Router>
        <Header style={styles.header}>
          <Logo />
          <MenuItems/>
          <div style={styles.headerRight}>
            <BrowserView>
              {isAuthenticated ? <Chains /> : ""}
            </BrowserView>
            <Account />
          </div>
        </Header>

        <div style={styles.content}>
          <Switch>
            <Route exact path="/market">
              <Market isServerInfo={isServerInfo} />
            </Route>
            <Route path="/erc20">
              <ERC20 />
            </Route>
            <Route path="/nfts">
              <NFTs />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <Redirect to="/market" />
            </Route>
            <Route path="/ethereum-boilerplate">
              <Redirect to="/market" />
            </Route>
            <Route path="/nonauthenticated">
              <>Please login using the "Authenticate" button</>
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer style={{ textAlign: "center" }}>
        <Text style={{ display: "block" }}>
          
        </Text>

        <Text style={{ display: "block" }}>
          
        </Text>

        <Text style={{ display: "block" }}>
         
        </Text>
      </Footer>
    </Layout>
  );
};

export const Logo = () => (
  <div style={{ display: "flex", fontWeight: "bold", fontSize: "22px", padding: "0px 10px", fontFamily: "Ledger"}}>
    <img alt="" src={"/logo_horizontal.svg"} style={{ height: "70px", maxWidth: "150px" }}/>
  </div>
);

export default App;
