import React, { useState, useEffect } from 'react';
import { useMoralis } from "react-moralis";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Category from '@mui/icons-material/Category';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Card } from 'antd';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import abi from "./abi_minting.js";
import watermark from 'watermarkjs';
import reduce from 'image-blob-reduce';

const Input = styled('input')({
  display: 'none',
});

function UploadButtons(props) {
  let fileList = []
  for (let i = 0; i < props.files.length; i++) {
    let file = props.files.item(i);
    fileList.push(file);
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <label htmlFor="file">
        {/*can add accept="image/*" later*/}
        <Input onChange={props.handleFUChange} id="file" multiple type="file" />
        <Button variant="contained" component="span">
          Choose asset
        </Button>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <Category />
        </IconButton>
      </label>
      <div>
        <div>
          {fileList.map((file)=>file.name).join(", ")}
        </div>
        <div id="formbox"></div>
      </div>
    </Stack>
  );
}

const nft_contract_address = "0x04e460D57E9Ea17299E4513FD7Cdb4D416e62181"

function NFTCreate() {
  const { Moralis, web3, authenticate, isAuthenticated, logout, account, chainId } = useMoralis();

  useEffect(() => {
    //console.log(account)
  });

  const [confirmedOwner, setConfirmedOwner] = React.useState(false);
  const [confirmedAssign, setConfirmedAssign] = React.useState(false);
  const [count, setCount] = React.useState(0);
  let URIs = [];
  let _count = 0;
  let expectedCount = 0;

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }


  // https://docs.moralis.io/moralis-server/cloud-code/cloud-functions#web3
  const upload = async () => {
    if(!(confirmedOwner && confirmedAssign)){
      alert("You have to complete the legal agreement.");
      return;
    }
    const fileInput = document.getElementById("file");
    const upload_one = async (data) => {
      let image;
      await watermark([data])
        .image(watermark.text.lowerRight('NFCop protected', '48px serif', '#fff', 0.5))
        .then(img => {
          image = img;
          });
      const original_width = image.width;
      const original_height = image.height;
      await reduce()
          .toBlob(data, { max: 350 })
          .then(img => { 
            image = img;
          });  
      await watermark([image])
        .image(watermark.text.lowerRight('NFCop', `${350/4}px serif`, '#fff', 0.5))
        .then(img => {
          image = img;
          });
      image.style.height="200px"
      document.getElementById('formbox').appendChild(image);
      const data_new = dataURLtoFile(image.src, data.name);
      const imageFile = new Moralis.File(data.name, data_new);

      document.getElementById("resultSpace").innerHTML =  
      `Uploading to IPFS...`;  
      await imageFile.saveIPFS();
      const imageURI = imageFile.ipfs();
      console.log(imageURI);
      document.getElementById("resultSpace").innerHTML =  
      `Uploaded to IPFS <a href="${imageURI} target="_blank">${imageURI}</a>. Minting token...`; 
      const metadata = {
        "name":document.getElementById("name").value,
        "description":document.getElementById("description").value,
        "image":imageURI,
        "width": image.width,
        "height": image.height,
        "original_width": original_width,
        "original_height": original_height
      }
      const metadataFile = new Moralis.File("metadata.json", {base64 : btoa(JSON.stringify(metadata))});
      await metadataFile.saveIPFS();
      const metadataURI = metadataFile.ipfs();
      URL.revokeObjectURL(image.src);
      return metadataURI;
    }
    if(fileInput.files.length == 1){
      const data = fileInput.files[0];
      const metadataURI = await upload_one(data);
      const tokenID = await mintToken(metadataURI).then(notify);  
      console.log(tokenID);
      const formData = new FormData();
      formData.append('file', data);
      formData.append('tokenID', tokenID);
      await fetch('https://api.nfcop.com/api/upload_image', {
        method: 'POST', // apparently the browser sets the header automatically
        body: formData,
        mode: 'no-cors'
      });

      }
    else{
      let data_mult = []
      for(var i=0; i<fileInput.files.length; i++){
        expectedCount = fileInput.files.length;
        const data = fileInput.files[i];
        data_mult.push(data);
        const metadataURI = await upload_one(data);
        URIs = [...URIs, metadataURI];
        console.log(URIs);    
        if(i == fileInput.files.length-1){
          const tokenIDs = await mintTokenBatch(URIs).then(notifyBatch);
          console.log(tokenIDs);
          for(var a=0; a<fileInput.files.length; a++){
            const img_data = data_mult[a];
            const formData = new FormData();
            formData.append('file', img_data);
            formData.append('tokenID', tokenIDs[a]);
            await fetch('https://api.nfcop.com/api/upload_image', {
              method: 'POST', // apparently the browser sets the header automatically
              body: formData,
              mode: 'no-cors'
            });
          }
        }
      };
    }

    
  }

  const mintToken = async (_uri) => {
    // JSON interface object - Application Binary Interface (ABI)
    const options = {
      contractAddress: nft_contract_address,
      functionName: "mint_NFTs",
      abi,
      params:{copyright_URI: _uri, licenceA_amount: 1000000},
      }
    const transaction = await Moralis.executeFunction(options); // see https://docs.moralis.io/moralis-server/web3/web3
    //console.log(transaction);
    //console.log(Moralis)

    // mint_NFTs
    return transaction;
  }

  const mintTokenBatch = async (_uriList) => {
    // JSON interface object - Application Binary Interface (ABI)
    const options = {
      contractAddress: nft_contract_address,
      functionName: "mint_batches_NFTs",
      abi,
      params:{copyright_URIs: _uriList, licenceA_amount: 1000000},
      }
    const transaction = await Moralis.executeFunction(options); // see https://docs.moralis.io/moralis-server/web3/web3
    //console.log(transaction);
    // mint_batches_NFTs
    return transaction;
  }
  
  const notify = async (_txt) => {
    console.log(_txt);
    const receipt = await _txt.wait();
    console.log(receipt);

    const blockhash = receipt.blockHash;
    document.getElementById("resultSpace").innerHTML =  
    `<div id="result" aria-label="URL" aria-describedby="basic-addon1">Your NFT was minted in transaction <a href="https://testnet.snowtrace.io/block/${blockhash}" target="_blank">${blockhash}</a>.</div>`;

    let tokenID = parseInt(receipt.events[0].topics[3]);
    console.log(tokenID);
    return tokenID;
  }

  const notifyBatch = async (_txt) => {
    console.log(_txt);
    const receipt = await _txt.wait();
    console.log(receipt);

    const blockhash = receipt.blockHash;
    document.getElementById("resultSpace").innerHTML =  
    `<div id="result" aria-label="URL" aria-describedby="basic-addon1">Your NFTs were minted in transaction <a href="https://testnet.snowtrace.io/block/${blockhash}" target="_blank">${blockhash}</a>.</div>`;

    let tokenIDs = []
    for (let i = 0; i < receipt.events.length/2; i++) {
      const tokenID = parseInt(receipt.events[i*2].topics[3]);
      tokenIDs.push(tokenID);
    }
    console.log(tokenIDs);
    return tokenIDs;
  }

  const [assetType, setAssetType] = React.useState(0);
  const [files, setFiles] = React.useState("");

  const handleATChange = (event) => {
    setAssetType(event.target.value);
  };

  const handleFUChange = (event) => {
    console.log(event.target.files)
    setFiles(event.target.files);
  };

  const assetIndex = ["Photo", "Graphic", "3D model"];

  return (
    <div>
      <h1>Mint an NFT</h1>
      <hr/>
      <div className="mb-3">    
        <div style={{display: "flex", flexDirection: "row"}}> 
          <div className="form-group">
            <div className="input-group mb-3" style={{margin: "10px"}}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Asset type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={assetType}
                    label="Asset type"
                    onChange={handleATChange}
                  >
                    <MenuItem value={0}>Photograph</MenuItem>
                    <MenuItem value={1}>Graphic</MenuItem>
                    <MenuItem value={2}>3D model</MenuItem>
                  </Select>
                </FormControl>
              </div>
            <div className="input-group mb-3" style={{margin: "10px"}}>
              <TextField id="name" label="NFT name" variant="outlined" defaultValue="My NFT"/>
            </div>
            <div className="input-group mb-3" style={{margin: "10px"}} key={assetType}>
              <TextField id="description" label="Description" variant="outlined" defaultValue={`${assetIndex[assetType]} of ...`}/>
            </div>
            <div className="input-group mb-3" style={{margin: "10px"}}>
              <UploadButtons handleFUChange={handleFUChange} files={files}/>
            </div>
          </div>
          <div>
            <Card
              style={{
                marginTop: "10px",
                borderRadius: "1rem",
              }}
              bodyStyle={{ padding: "15px" }}
            >
              Legal Agreement
              <p>I agree to transfer my copyright to whoever owns the token.</p>
              <FormGroup>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormControlLabel onChange={setConfirmedOwner} control={<Checkbox />} label="I confirm that I am the rights owner." />
                  <FormControlLabel onChange={setConfirmedAssign} control={<Checkbox />} label="I agree to assign my copyright." />
                </div>
              </FormGroup>
            </Card>
          </div>
        </div>
        <br/>
        <div style={{margin: "10px 10px 20px 10px"}}>  
          <Button variant="contained" className="btn btn-primary" id="upload" onClick={()=>upload()}>Upload and mint</Button>
          <div style={{marginTop: "10px"}} className="input-group mb-3" id="resultSpace">
          </div>
        </div>
        <hr/>
          
      </div>
    </div>
  );
}

export default NFTCreate;