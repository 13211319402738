import Box from '@mui/material/Box';

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }} style={{padding: "5px 0px 0px 0px"}}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }