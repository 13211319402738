import { useLocation } from "react-router";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import Icon from '@mui/material/Icon';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function MenuItems(props) {
  const { pathname } = useLocation();

  return (
    <Menu
      theme="light"
      mode="horizontal"
      style={{
        display: "flex",
        fontSize: "17px",
        fontWeight: "500",
        width: isMobile ? "100px" : "100%",
        justifyContent: "center",
      }}
      defaultSelectedKeys={[pathname]}
    >
      <Menu.Item key="/market">
        <NavLink to="/market">{isBrowser?<Icon>auto_graph</Icon>:""} Market</NavLink>
      </Menu.Item>
      <Menu.Item key="/nfts">
        <NavLink to="/nfts">{isBrowser?<Icon>category</Icon>:""} NFTs</NavLink>{/*could also use inventory*/}
      </Menu.Item>
      <Menu.Item key="/erc20">
        <NavLink to="/erc20">{isBrowser?<Icon>account_balance</Icon>:""} Balances</NavLink>
      </Menu.Item>
      <Menu.Item key="/about">
        <NavLink to="/about">{isBrowser?<Icon>groups</Icon>:""} About</NavLink>
      </Menu.Item>
    </Menu>
  );
}

export default MenuItems;
