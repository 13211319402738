//import { Card, Timeline, Typography } from "antd";
import React from "react";
import { useMoralis } from "react-moralis";
//import Gallery from "react-photo-gallery";
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';

//const { Text } = Typography;

const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};

export default function About({ }) {
  const { Moralis } = useMoralis();
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <h2>Mission</h2>
      <div style={{width: "500px", padding: "20px"}}>
        <p>NFCop is the digital rights management platform made for creators. 
          We allow photographers and digital artists to sell their copyright or transferable licenses of their works.
          The license tokens can be written to store the usage information such as a domain, which makes it easy to see if digital assets are used without a license.
          We vigorously defend our clients by scraping websites and token marketplaces and assist them in the legal steps.
          Our platform is secured with the Avalanche blockchain with low transaction costs and high speed.
        </p>
      </div>
      <h2>Getting started</h2>
      <div style={{width: "500px", padding: "20px"}}>
        <p>In order to use NFCop you need to have Metamask. You can download it from the <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn">Chrome web store</a> or from a mobile app store.
        </p>
        <br/>
        <h3>Testnet</h3>
        <p>You can get AVAX from the <a target="_blank" href="https://faucet.avax-test.network/">Testnet Faucet</a>.</p>
        <br/>
        <h3>Mainnet</h3>
        <p>
          You can get AVAX from the exchange or DEX of your choice.
          You can add Avalanche as a network to Metamask as explained <a target="_blank" href="https://support.avax.network/en/articles/4626956-how-do-i-set-up-metamask-on-avalanche">here</a>.
          You can switch to the Fuji Testnet after clicking "authenticate" on our website with the network selector next to your wallet address.
        </p>
      </div>
      {/*<h2>Team</h2>*/}

    </div>
  );
}
